<template>
    <Breadcrumb page-title="Report A Bug" page-section="Pages"> </Breadcrumb>
     <section class="content">
        <div class="box box-info">
            <div class="box-body">
                <div class="row">
                  <div class="col-md-8 form-horizontal">
                    <div class="form-group">
                      <label class="col-sm-8 control-label text-left"></label>
                    </div>  
                    <div class="form-group">
                      <label class="col-sm-4 control-label text-left">User Name
                         <span v-tooltip="{text: 'We will create the bug request using your login details. The iService team will follow up with you at this address if additional information is needed.',theme: {placement: 'bottom', }}">   
                         <i class="fa  fa-question-circle text-info help-icon" ></i>
                         </span>
                      </label>
                      <div class="col-sm-6">
                        <input type="text" value="scott" class="form-control" disabled="">
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="col-sm-4 control-label text-left">Email</label>
                      <div class="col-sm-6">
                        <input type="text" value="scott@1t01service.com" class="form-control" disabled="">
                      </div>
                    </div>
                    <div class="form-group" style="display: none;">
                      <label class="col-sm-4 control-label text-left">Submitting URL</label>
                      <div class="col-sm-6">
                        <input type="hidden" value="" class="form-control" autocomplete="off">
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="col-sm-4 control-label text-left">URL of the page with the bug
                        <span v-tooltip="{text: 'Please copy and paste the exact URL from your browsers address bar for the page that had the problem.',theme: {placement: 'bottom', }}">
                            <i class="fa fa-question-circle text-info help-icon"></i>
                        </span>
                       </label>
                      <div class="col-sm-6">
                        <input type="text" value="" class="form-control">
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="col-sm-4 control-label text-left">How to reproduce the error
                        <span v-tooltip="{text: 'Please do your best to tell us how to reproduce  your error. Be as detailed as possible.',theme: {placement: 'bottom', }}">
                            <i class="fa fa-question-circle text-info help-icon"></i>
                        </span>
                      </label>
                      <div class="col-sm-6">
                        <textarea class="form-control"></textarea>
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="col-sm-4 control-label text-left">Error message displayed
                        <span v-tooltip="{text: 'Provide any error messages that were displayed on the page.',theme: {placement: 'bottom', }}">
                            <i class="fa fa-question-circle text-info help-icon"></i>
                        </span>
                       </label>
                      <div class="col-sm-6">
                        <textarea class="form-control"></textarea>
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="col-sm-4 control-label text-left">Description of the problem
                        <span v-tooltip="{text: 'Provide a short description of the problem and how it affected your workflow.',theme: {placement: 'bottom', }}">
                            <i class="fa fa-question-circle text-info help-icon"></i>
                        </span>
                      </label>
                      <div class="col-sm-6">
                        <textarea class="form-control"></textarea>
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="col-sm-4 control-label text-left">Attachments</label>
                      <div class="col-sm-6">
                        <iservice-files-upload ng-init="uploads = []" files="uploads" class="ng-isolate-scope"><div class="form-group">
                        <div class="col-md-12">
                            <label class="drop-box">
                               Drop files here or click to upload
                            <input type="file" class="hide" multiple="">
                            </label>
                            <div>(Maximum 10 MB upload at one time.)</div>
                            <div class="imageuploadify">
                            <!-- ngRepeat: file in files -->
                            </div>
                        </div>
                        </div></iservice-files-upload>
                  
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="col-sm-4 control-label text-left">&nbsp;</label>
                      <div class="col-md-6">
                        <button type="button" class="btn btn-primary margin-r-5">Submit Request</button>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
        </div>
     </section>
   </template>
   
   <script>
   // @ is an alias to /src
   import Breadcrumb from '../components/Breadcrumb.vue';
  
   export default {
     name: 'Credit Memo Approval Form',
     components: {
       Breadcrumb,
       
     },
  data: function(){
      return{
        segment1 : false,
        segment2 : false,
        segment3 : false,
        segment4 : false
      }
         
  },
   methods: {
    toggleSegmentCollapsedState(i) {
      this['segment'+i] = !this['segment'+i] 
    }
  },
     ready () {
       $(document).ready(function ($) {
       })
     }
   }
   </script>